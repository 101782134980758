import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import CardsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/CardsBlock"
import QuicklinksBlock from "../../../../../themes/gatsby-theme-rothenberger/src/components/blocks/QuicklinksBlock"
import FeatureCardsBlock from "gatsby-theme-rothenberger/src/components/blocks/FeatureCardsBlock"
import FeatureSectionBlock from "gatsby-theme-rothenberger/src/components/blocks/FeatureSectionBlock"
import HeroOverlayBlock from "gatsby-theme-rothenberger/src/components/blocks/HeroOverlayBlock"

const RedesignPage = () => {
  return (
    <Layout language="de">
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Hero Block</h2>
      </BlockWrapper>
      <HeroBlock
        block={{
          headline: "Brillante Bildqualität und intuitive Bedienung",
          media_height: "sm",
          video: {
            filename:
              "https://a.storyblok.com/f/154111/x/aaeac201a4/ruko-produktion.mp4",
          },
          text: "Jetzt kannst du Rohre noch schneller und bequemer auf Verstopfungen und Schäden überprüfen.",
          buttons: [
            { link_text: "Jetzt kaufen", link: "/" },
            { link_text: "Mehr erfahren", link: "/" },
          ],
        }}
        breadcrumbs={[{ link_text: "ROCAM Mini HD", link: "/" }]}
      />
      <HeroBlock
        block={{
          headline: "Brillante Bildqualität und intuitive Bedienung",
          image: {
            filename:
              "https://a.storyblok.com/f/125728/2550x996/a0b6ae6d2f/ampshare_product-range-hero-image-ampshare-logo.jpg",
          },
          text: "Jetzt kannst du Rohre noch schneller und bequemer auf Verstopfungen und Schäden überprüfen.",
          buttons: [
            { link_text: "Jetzt kaufen", link: "/" },
            { link_text: "Mehr erfahren", link: "/" },
          ],
        }}
        breadcrumbs={[{ link_text: "ROCAM Mini HD", link: "/" }]}
      />
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Hero Overlay Block</h2>
      </BlockWrapper>
      <HeroOverlayBlock
        block={{
          headline: "Brillante Bildqualität und intuitive Bedienung",
          video: {
            filename:
              "https://a.storyblok.com/f/154111/x/aaeac201a4/ruko-produktion.mp4",
          },
          text: "Jetzt kannst du Rohre noch schneller und bequemer auf Verstopfungen und Schäden überprüfen.",
          buttons: [
            { link_text: "Jetzt kaufen", link: "/" },
            { link_text: "Mehr erfahren", link: "/" },
          ],
        }}
        breadcrumbs={[{ link_text: "ROCAM Mini HD", link: "/" }]}
      />
      <HeroOverlayBlock
        block={{
          headline: "Brillante Bildqualität und intuitive Bedienung",
          image: {
            filename:
              "https://a.storyblok.com/f/125728/2550x996/a0b6ae6d2f/ampshare_product-range-hero-image-ampshare-logo.jpg",
          },
          text: "Jetzt kannst du Rohre noch schneller und bequemer auf Verstopfungen und Schäden überprüfen.",
          buttons: [
            { link_text: "Jetzt kaufen", link: "/" },
            { link_text: "Mehr erfahren", link: "/" },
          ],
        }}
        breadcrumbs={[{ link_text: "ROCAM Mini HD", link: "/" }]}
      />
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Feature Section Block</h2>
      </BlockWrapper>
      <FeatureSectionBlock
        block={{
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          feature_image: {
            filename:
              "https://a.storyblok.com/f/125728/1200x469/f9efc60a78/supertronic-2000-e-ampshare.jpg",
          },
          feature_headline: "Das Feature",
          feature_text: "Interessante Beschreibung zum Feature",
          feature_link: "https://www.google.com",
          feature_link_text: "Mehr erfahren",
        }}
      />
      <FeatureSectionBlock
        block={{
          block_bg: "bg-ro-gray-100",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          feature_image: {
            filename:
              "https://a.storyblok.com/f/125728/1200x469/f9efc60a78/supertronic-2000-e-ampshare.jpg",
          },
          feature_headline: "Das Feature",
          feature_text: "Interessante Beschreibung zum Feature",
          feature_link: "https://www.google.com",
          feature_link_text: "Mehr erfahren",
        }}
      />
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Feature Cards Block</h2>
      </BlockWrapper>
      <FeatureCardsBlock
        block={{
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              link: "https://www.google.com",
              link_text: "Ganz viel mehr zu diesen besonderen Feature erfahren",
              text: "Eine sehr interessante und lange Beschreibung zum Feature 1. Eine sehr interessante und lange Beschreibung zum Feature 1. Eine sehr interessante und lange Beschreibung zum Feature 1. Eine sehr interessante und lange Beschreibung zum Feature 1.",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              image_fitin: true,
              headline:
                "Feature 1 mit einem langen Namen. Feature 1 mit einem langen Namen. Feature 1 mit einem langen Namen. Feature 1 mit einem langen Namen.",
            },
            {
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
              text: "Interessante Beschreibung zum Feature 2",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              headline: "Feature 2",
            },
          ],
        }}
      />
      <FeatureCardsBlock
        block={{
          block_bg: "bg-ro-gray-100",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
              text: "Interessante Beschreibung zum Feature 1",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              image_fitin: true,
              headline: "Feature 1",
            },
            {
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
              text: "Interessante Beschreibung zum Feature 2",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              headline: "Feature 2",
            },
          ],
        }}
      />
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Cards Block als Slider</h2>
      </BlockWrapper>
      <CardsBlock
        block={{
          view: "slider",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              kicker: "Kicker kicker",
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description Description Description Description Description Description",
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description",
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
          ],
        }}
      />
      <CardsBlock
        block={{
          block_bg: "bg-ro-gray-100",
          card_variant: "campaign1by1",
          view: "slider",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              kicker: "Kicker kicker",
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description",
              link: "https://www.google.com",
              link_text: "Ganz viel mehr zu diesen Thema erfahren",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description",
              link: "https://www.google.com",
              link_text: "Mehr erfahren",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              image: {
                filename:
                  "https://a.storyblok.com/f/125728/1280x720/7646a8fb0d/ampshare_product-range_1_1280x720px.jpg",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
          ],
        }}
      />
      <CardsBlock
        block={{
          view: "slider",
          kicker: "",
          block_bg: "",
          headline: "Entdecke die volle Maschinenpower",
          card_variant: "product4by3",
          card_items: [
            {
              link: "https://www.google.com",
              text: "Pressmaschine",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              headline: "ROMAX COMPACT III",
              image_fitin: true,
              link_text: "Mehr erfahren",
            },
            {
              link: "https://www.google.com",
              text: "Gewindeschneidmaschine",
              image: {
                alt: "SUPERTRONIC 2000 E AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1200x469/f9efc60a78/supertronic-2000-e-ampshare.jpg",
              },
              headline: "SUPERTRONIC 2000 E",
              image_fitin: true,
              link_text: "Mehr erfahren",
            },
            {
              link: "https://www.google.com",
              text: "Pressmaschine",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              headline: "ROMAX COMPACT III",
              image_fitin: true,
            },
            {
              link: "https://www.google.com",
              text: "Gewindeschneidmaschine",
              image: {
                alt: "SUPERTRONIC 2000 E AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1200x469/f9efc60a78/supertronic-2000-e-ampshare.jpg",
              },
              headline: "SUPERTRONIC 2000 E",
              image_fitin: true,
            },
            {
              link: "https://www.google.com",
              text: "Pressmaschine",
              image: {
                alt: "ROMAX COMPACT III AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1245x3065/a45f1e0311/1000004266_romax_compact_iii_amp_p02_2.jpg",
              },
              headline: "ROMAX COMPACT III",
              image_fitin: true,
            },
            {
              link: "https://www.google.com",
              text: "Gewindeschneidmaschine",
              image: {
                alt: "SUPERTRONIC 2000 E AMPShare",
                filename:
                  "https://a.storyblok.com/f/125728/1200x469/f9efc60a78/supertronic-2000-e-ampshare.jpg",
              },
              headline: "SUPERTRONIC 2000 E",
              image_fitin: true,
            },
          ],
          show_slides: "4",
          subheadline: "Mit den AMPShare-Akkuwerkzeugen von ROTHENBERGER",
        }}
      />
      <BlockWrapper
        block={{}}
        blockPadding="py-2"
        className="bg-green-700 text-ro-white"
      >
        <h2 className="headline headline-h2">Quicklinks Block</h2>
      </BlockWrapper>
      <QuicklinksBlock
        block={{
          view: "slider",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description Description Description Description Description Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
          ],
        }}
      />
      <QuicklinksBlock
        block={{
          block_bg: "bg-ro-gray-100",
          view: "slider",
          headline: "Title",
          text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.",
          link_items: [
            {
              text: "Title",
              link: "https://www.google.com",
            },
            {
              text: "Title",
              link: "https://www.google.com",
            },
          ],
          card_items: [
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description Description Description Description Description Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline:
                "Title Title Title Title Title Title Title Title Title Title Title Title Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
            {
              icon: {
                filename:
                  "https://a.storyblok.com/f/125728/512x456/75ff9b87c9/test-icon.png",
              },
              headline: "Title",
              text: "Description",
              link: "https://www.google.com",
            },
          ],
        }}
      />
    </Layout>
  )
}

export default RedesignPage
